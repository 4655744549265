import React, { useState, useEffect, useRef } from 'react';
import { Container, Col, Button, Row } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { Action, EntityType, EntityStatus, Role } from '../../helpers/enums';
import { callAPI } from '../../helpers/api';
import { Error } from './Error';
import { firstLetterToUpperCase } from '../../helpers/commonHelpers';
import CustomTableWithFilters from './CustomTableWithFilters';
import DeleteModal from './modals/DeleteModal';
import SimpleCustomTable from './SimpleCustomTable';
import PublishModal from './modals/PublishModal';
import SettingsModal from './modals/SettingsModal';
import LogModal from './modals/LogModal';
import EntityLayoutModal from '../workspace/modals/EntityLayoutModal';
import FilterRow from './FilterRow';
import ButtonRow from './ButtonRow';
import ButtonRowForTable from './ButtonRowForTable';
import { ItemBaseColumns } from '../workspace/Columns';
import workspaceActions from '../../actions/workspaceActions';
import { useLocalStorage } from '../../helpers/localStorageHelpers';
import { CustomSpinner } from '../common/CustomSpinner';
import { isStorageOwner } from '../../helpers/commonHelpers';
import { getEntityProperties } from './EntityProperties';
import { checkAndGetPopulateProperties } from '../../helpers/entityHelpers';
import applicationActions from '../../actions/applicationActions';
import { getActionsColumnDef, getDefaultHiddenColumnsArray } from '../../helpers/tableHelpers';
//import TestTable from './TestTable';
//import TestModal from '../workspace/modals/TestModal';

export default function EntityLayout({
    type, 
    entityType, 
    enabledActions, 
    tableStyle, 
    columns, 
    setValues, 
    logColumns, 
    onSelectedEntities, 
    categories = [], 
    onEntitiesUpdated, 
    refreshEntities, 
    onEntitiesRefreshed,
    combinedColumns,
    msalInstance,
    onEntitiesFetched = null,
    children,
    flags = []
}){
    const entityProperties = getEntityProperties(entityType, categories, flags);
    
    if (!entityProperties){
        return (<Error msg={"No entityProperties found! Check configurations from EntityLayout!"}></Error>)
    }

    return (
        <InternalEntityLayout
            type={type}
            entityProperties={entityProperties}
            enabledActions={enabledActions}
            columns={columns}
            setValues={setValues}
            logColumns={logColumns}
            onSelectedEntities={onSelectedEntities}
            onEntitiesUpdated={onEntitiesUpdated}
            refreshEntities={refreshEntities}
            onEntitiesRefreshed={onEntitiesRefreshed}
            tableStyle={tableStyle}
            combinedColumns={combinedColumns}
            msalInstance={msalInstance}
            onEntitiesFetched={onEntitiesFetched}
        >
            { children }
        </InternalEntityLayout>
    )
}

function InternalEntityLayout({
    type,  
    entityProperties, 
    enabledActions, 
    columns, 
    setValues, 
    logColumns, 
    onSelectedEntities,
    onEntitiesUpdated, 
    refreshEntities, 
    onEntitiesRefreshed, 
    tableStyle,
    combinedColumns,
    msalInstance,
    onEntitiesFetched,
    children
}) {
    const entityType = entityProperties.type; 
    const separateFilters = entityProperties.filters && entityProperties.filters.length > 0 ? entityProperties.filters : [];//console.log('separateFilters', separateFilters);
    const tableInstance = useRef(null);
    const localStorageKey = entityProperties.single + "Settings";
    const defaultHiddenColumns = getDefaultHiddenColumnsArray(columns);//columns && columns.length > 0 ? columns.filter(i => i.default === false) : [];
    const customStyles = tableStyle ?? "";
    const tableClasses = entityProperties.revisionEntity || entityProperties.tableWithStates ?
        "table-with-states " + entityType?.toLowerCase() + " " + customStyles :
        entityType?.toLowerCase() + " " + customStyles
    
    const noEntitiesFoundMsg = "No " + entityProperties.plural + " found" +
    (entityType === EntityType.ELEMENT ? " with the selected type and factory filter" : "") +"!";

    const dispatch = useDispatch();

    let currentUser = useSelector(state => state.application.user); //console.log('currentUser', currentUser);
    let isUnauthorize = useSelector(state => state.application.userUnauthorized);
    let selectedStorageId = useSelector(state => state[type].selectedStorageId) ?? 0;
    let selectedStorage = useSelector(state => state[type].activeItem);
    let selectedFactories = useSelector(state => state[type].selectedFactories);
    let selectedElementTypes = useSelector(state => state[type].selectedElementTypes);
    let selectedItems = useSelector(state => state[type].selectedItems);//console.log('selectedItems', selectedItems);
    let selectedUnitGroups = useSelector(state => state[type].selectedUnitGroups);
    let storageDataLoading = useSelector(state => state[type].storageDataLoading);
    let entitiesLoading = useSelector(state => state[type].entitiesLoading);

    const [entities, setEntities] = useState([]);
    const [filteredRowsCount, setFilteredRowsCount] = useState(0);
    const [selectedEntities, setSelectedEntities] = useState([]);
    const [selectedEntity, setSelectedEntity] = useState(null);
    const [deleteEntities, setDeleteEntities] = useState([]);
    const [clearFilters, setClearFilters] = useState(false);
    
    const [loading, setIsLoading] = useState(storageDataLoading);
    const [searchError, setSearchError] = useState(null);
    const [infoMsg, setInfoMsg] = useState(null);

    const [modalStatus, setModalStatus] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showPublishModal, setShowPublishModal] = useState(false);
    const [showSettingsModal, setShowSettingsModal] = useState(false);
    const [showLogModal, setShowLogModal] = useState(false);
    const [hiddenColumns, setHiddenColumns] = useLocalStorage(localStorageKey, defaultHiddenColumns);//.length > 0 ? defaultHiddenColumns.map(d => d.accessorKey) : []);
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [clearFilterDisabled, setClearFilterDisabled] = useState(true);
    const [publishStatus, setPublishStatus] = useState(EntityStatus.STAGE);
    //console.log('hiddenColumns for ' + entityProperties.plural, hiddenColumns)

    const toggleModal = (action, selectedEntity) => {
        const modalIsAboutToOpen = !showModal; //console.log('toggle, is opening: ', modalIsAboutToOpen, selectedEntity);
        //console.log('tableInstance?.current', tableInstance?.current);
        if (action === Action.CREATE || !modalIsAboutToOpen) {
            setSelectedEntity(null);
        }
        if (selectedEntity && modalIsAboutToOpen) {
            setSelectedEntity(selectedEntity);
        }
        setShowModal(modalIsAboutToOpen);
        if (typeof action === "string") {
            setModalStatus(action);
        }
        else {
            setModalStatus(null);
        }
    }

    const toggleDeleteModal = (selectedEntity = null) => {
        //console.log('delete', selectedEntity);
        if (selectedEntity?.id > 0) {
            setDeleteEntities([selectedEntity])
        }
        else {
            setDeleteEntities(selectedEntities);
        }
        setShowDeleteModal(!showDeleteModal);
    }
    const togglePublishModal = () => {
        setShowPublishModal(!showPublishModal);
    }
    const toggleSettingsModal = () => {
        setShowSettingsModal(!showSettingsModal);
    }
    const toggleLogModal = (selectedEntity) => {
        //console.log('selectedEntity', selectedEntity);
        if (selectedEntity && selectedEntity.id > 0){
            setSelectedEntity(selectedEntity);
        }
        setShowLogModal(!showLogModal);
    }

    const setSelectedRows = (entities) => {
        //console.log('selected entities from setSelectedRows', entities);
        setSelectedEntities(entities);
        setDeleteEntities(entities);
        if (onSelectedEntities) {
            onSelectedEntities(entities);
        }
    }

    const onSelectedChanged = (selectedIds) => {
        //console.log('selectedIds, entities', selectedIds, entities);
        if (selectedIds?.length > 0) {
            setSelectedRows(entities.filter(d => selectedIds.includes(d.id)));
        }
        else {
            setSelectedRows([]);
        }
    }

    const entityListChanged = (entities, deletedEntities) => {
        if (onEntitiesUpdated) {
            //console.log('entities', entities);
            if (entityType === EntityType.ELEMENT) {
                let itemIds = entities ? entities.map(e => e.itemId) : [];
                if (deletedEntities && deletedEntities.length > 0) {
                    // Let's transfer the item id's where elements were deleted.
                    itemIds = deletedEntities.map(e => e.itemId);
                }
                const distinctItemIds = itemIds.filter((value, index, self) => { return self.indexOf(value) === index });
                onEntitiesUpdated(distinctItemIds); //console.log('elements updated for items: ', distinctItemIds);
            }
            else {
                onEntitiesUpdated(entities);
            }
        }
    }

    const entitiesDeleted = (deletedEntities) => {
        //console.log('Deleted entitiess', deletedEntities);console.log('selectedEntities', selectedEntities);
        //console.log('globalFilterValue & globalFilters & tableInstance.current:', globalFilterValue, globalFilters, tableInstance.current)
        if (deletedEntities.length > 0){
            const ids = deletedEntities.map(e => e.id);
            const notDeletedSelectedEntities = selectedEntities.filter(e => !ids.includes(e.id));//console.log('notDeletedSelectedEntities', notDeletedSelectedEntities);
            setSelectedEntities(notDeletedSelectedEntities);
            setDeleteEntities(notDeletedSelectedEntities);
            setSelectedEntity(null);
            if (notDeletedSelectedEntities.length === 0){
                tableInstance.current?.toggleAllRowsSelected(false);
            }
            let newList = entities.filter(e => !deletedEntities.map(d => d.id).includes(e.id));
            setEntities(newList);
            if (entities.length === 0){
                setSearchError(noEntitiesFoundMsg);
            }
            entityListChanged(newList, deletedEntities);
        }
    }

    const deleteModalIsCancelClosed = () => {
        //console.log('delete modal is closed by cancel');
        setDeleteEntities(selectedEntities);
    }

    const entitiesPublished = (publishedEntities) => {
        //console.log('publishedEntities', publishedEntities);
        if (publishedEntities.length > 0){
            let newList = entities;
            publishedEntities.forEach(entity => {
                var index = newList.findIndex(i => i.id === entity.id);
                if (index >= 0){
                    setValues(newList[index], entity, Action.PUBLISH);
                }
            });
            setEntities([...newList]);
            entityListChanged(newList);
        }
    }

    const entityIsCreated = (entity) => {
        //console.log('created entity, all entities', entity, entities, tableInstance.current);
        //console.log('globalFilterValue & globalFilters & tableInstance.current:', globalFilterValue, globalFilters, tableInstance.current)
        setSearchError(null);
        let newItem = {...entity};
        setValues(newItem, entity, Action.CREATE);
        let newList = [...entities, newItem];
        setEntities(newList);
        entityListChanged(newList);
        setSelectedEntity(null);
        setModalStatus(null);
    }

    const entityIsUpdated = (entity) => {
        //console.log('updated entity', entity);
        let newList = entities;//[...entities];
        var index = newList.findIndex(i => i.id === entity.id);
        if (index >= 0){
            setValues(newList[index], entity, Action.EDIT);
        }
        setEntities([...newList]);
        entityListChanged(newList);
    }

    const onSettingsUpdated = (data) => {
        //console.log('Settings for entities', data);
        setHiddenColumns(data);
    }

    const populateEntities = async (params) => {
        //console.log('params', params);
        setIsLoading(false);
        setSearchError(null);
        setInfoMsg(null);
        if (entityType === EntityType.ITEM) {
            dispatch(workspaceActions.setSelectedItems([]));
        }

        if (!isUnauthorize) {
            const checkResult = checkAndGetPopulateProperties(params);

            if (checkResult) {
                if (checkResult.canPopulate) {
                    dispatch(workspaceActions.setEntitiesLoading(true));
                    entitiesLoading = true;
                    setIsLoading(true);
                    let url = entityProperties.baseUrl + checkResult.url; //console.log('Populate ' + entityType, url);
                    let result = await callAPI(url, msalInstance, currentUser.authUser); //console.log('result', result)
                    var entityResult = result.json;
                    if (result.errorMsg && !entityResult) {
                        setSearchError(result.errorMsg);
                        setEntities([]);
                        dispatch(applicationActions.setUserUnauthorized(result.unAuthorized ?? false));
                    }
                    else {
                        // Set the type values for UI
                        if (entityResult.result) {
                            entityResult = entityResult.result;
                        }
                        if (onEntitiesFetched) {
                            onEntitiesFetched(entityResult);
                        }
                        if (checkResult.filterEntities) {
                            entityResult = checkResult.filterEntities(entityResult);
                        }
                        entityResult.forEach(e => {
                            setValues(e, { ...e });
                        });
                        setEntities(entityResult);//console.log('entityResult', entityResult);
                        if (entityResult.length > 0) {
                            setSearchError(null);
                            if (entityType === EntityType.ITEM) {
                                dispatch(workspaceActions.setNoItemsFound(false));
                            }
                        }
                        else {
                            setSearchError(noEntitiesFoundMsg);
                            if (entityType === EntityType.ITEM) {
                                dispatch(workspaceActions.setNoItemsFound(true));
                            }
                        }
                    }
                    if (onEntitiesRefreshed) {
                        onEntitiesRefreshed();
                    }
                    setIsLoading(false);
                    dispatch(workspaceActions.setEntitiesLoading(false));
                }
                else {
                    if (checkResult.infoMsg) {
                        setInfoMsg(checkResult.infoMsg);
                    }
                    setEntities([]);
                }
            }
        }
    }

    const resetDefinedEntities = async (ids) => {
        //console.log('reset items: ', ids);
        let url = entityProperties.baseUrl + "?ids=" + ids.join(';') + "&storageId=" + selectedStorageId;//console.log('Populate ' + entityType, url);
        let result = await callAPI(url, msalInstance, currentUser.authUser); 
        var entityResult = result.json;
        if (result.errorMsg && !entityResult) {
            setSearchError(result.errorMsg);
        }
        else {
            // Set the type values for UI
            if (entityResult.result) {
                entityResult = entityResult.result;
            }
            if (entityResult?.length > 0) {
                let newList = entities;
                entityResult.forEach(entity => {
                    var index = newList.findIndex(i => i.id === entity.id);
                    if (index >= 0) {
                        setValues(newList[index], entity);
                    }
                });
                setEntities([...newList]);
                //render();
                entityListChanged();
                if (onEntitiesRefreshed) {
                    onEntitiesRefreshed();
                }
            }
        }
    }

    const checkAndSetClearFilter = () => {
        const columnFilters = tableInstance?.current ? tableInstance.current.getState().columnFilters : [];
        const globalFilter = tableInstance?.current ? tableInstance.current.getState().globalFilter : "";
        //console.log('filters changed', columnFilters?.length > 0 || globalFilter?.length > 0, columnFilters, globalFilter);
        if (columnFilters?.length > 0 || globalFilter?.length > 0 || globalFilterValue?.length > 0) {
            setClearFilterDisabled(false);
        }
        else {
            setClearFilterDisabled(true);
        }
    }

    const filterChanged = (el, global = false) => { 
        //console.log('global, filter el:', global, el.id, el.value, typeof el.value); console.log('clearFilters, separateFilters', clearFilters, separateFilters);// console.log('is global', global);
        setClearFilters(false);
        // Let's empty all selected rows
        setSelectedRows([]);
        if (separateFilters.length > 0){
            checkAndSetClearFilter();
        }
        if (!global) {
            if (tableInstance?.current) {
                //console.log('el', el, [...tableInstance.current.getState().columnFilters.filter(c => c.id !== el.id), { ...el }]);
                tableInstance.current.setColumnFilters([...tableInstance.current.getState().columnFilters.filter(c => c.id !== el.id), { ...el }]);
            }
        }
        else {
            setGlobalFilterValue(el.value)
        }
        // Empty all selected rows
        if (tableInstance?.current?.toggleAllRowsSelected){ 
            //console.log('tableInstance.current.toggleAllRowsSelected');
            tableInstance.current.toggleAllRowsSelected(false);
        }
        //console.log(tableInstance?.current.getState());
    }

    const rowCountChanged = (count) => {
        //console.log('filters changed', count, tableInstance?.current.getState());
        setFilteredRowsCount(count);
        checkAndSetClearFilter();
    }

    const resetColumnFilters = () => {
        if (tableInstance?.current?.resetColumnFilters) {
            tableInstance.current.resetColumnFilters(); //console.log('Filters emptied from Entity layout!');
        }
    }

    const clearFiltersClicked = () => {
        setClearFilters(true);
        setGlobalFilterValue("");
        setClearFilterDisabled(true);
        // Empty all selected rows
        setSelectedRows([]);
        if (tableInstance?.current?.toggleAllRowsSelected){ 
            // Deselect all rows
            tableInstance.current.toggleAllRowsSelected(false);
        }
        resetColumnFilters();
    }

    const publish = (status) => {
        //console.log('publish to', status);
        setPublishStatus(status);
        togglePublishModal();
    }

    const deselectAll = () => {
        //console.log("We should deselect all!");
        // Empty all selected rows
        if (tableInstance.current && tableInstance.current.toggleAllRowsSelected){
            tableInstance.current.toggleAllRowsSelected(false);
        }
        setSelectedEntities([]);
    }

    if (enabledActions?.length > 0) {
        //console.log('enabledActions', enabledActions);
        enabledActions.forEach(button => {
            switch (button.action) {
                case Action.CREATE:
                    button.onClick = toggleModal;
                    break;
                case Action.EDIT:
                    // To see the edit button user needs to have contributor role for revision entities
                    // and owner role for entities that are NOT revision entities.
                    button.requiredRole = entityProperties.revisionEntity ? Role.CONTRIBUTOR : Role.OWNER;
                    button.onClick = toggleModal;
                    break;
                case Action.VIEW:
                    button.onClick = toggleModal;
                    break;
                case Action.COPY:
                    button.onClick = toggleModal;
                    break;
                case Action.DELETE:
                    button.onClick = toggleDeleteModal;
                    break;
                case Action.PUBLISH:
                    button.onClick = publish;
                    break;
                case Action.SETTINGS:
                    button.onClick = toggleSettingsModal;
                    break;
                case Action.LOG:
                    button.onClick = toggleLogModal;
                    break;
                default:
                    break;
            }
        });
    }

    const cellFn = (original) => {
        return (
            <ButtonRowForTable type={type}
                buttons={enabledActions}
                selectedEntity={original}
            />
        );
    }
    const columnsWithActions = [...columns, getActionsColumnDef(cellFn)];

    useEffect(() => {
        if (entityType !== EntityType.ITEM) {
            populateEntities({
                entityType: entityType,
                storageId: selectedStorageId,
                items: selectedItems,
                factories: selectedFactories,
                elementTypes: selectedElementTypes,
                unitGroups: selectedUnitGroups
            });
        }
    }, [selectedItems, selectedFactories, selectedElementTypes, selectedUnitGroups]); // eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect(() => {
        //console.log('selectedStorageId, entityType', selectedStorageId, entityType, globalFilterValue);
        if (entityType === EntityType.ITEM) {
            populateEntities({
                entityType: entityType,
                storageId: selectedStorageId
            });
        }
    }, [selectedStorageId, entityType]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        //console.log('refreshEntities', refreshEntities);
        if (refreshEntities && refreshEntities.length > 0 && entityType === EntityType.ITEM) {
            resetDefinedEntities(refreshEntities);
        }
    }, [refreshEntities, entityType]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => { 
        if (storageDataLoading){
            setIsLoading(true); //console.log('storage data loading');
            // Empty all other filters than the global ones
            resetColumnFilters();
            if (globalFilterValue.length === 0){
                setClearFilterDisabled(true);
            }
            setSelectedEntities([]);
        }
        else if (!entitiesLoading){
            setIsLoading(false);
        }
    }, [storageDataLoading, entitiesLoading]); // eslint-disable-line react-hooks/exhaustive-deps

    if (!entityProperties){
        return(
            <Error msg="No base entity found, check EntityType and EntityObject!" />
        )
    }

    return (
        <React.Fragment>
            {children}
            <Container fluid={true}>
                <Col sm={12}>
                    <h2 className="mt-3">{ firstLetterToUpperCase(entityProperties.plural) }
                        <span className="heading-info ms-2">{filteredRowsCount} / {entities.length}</span>
                        { selectedEntities && selectedEntities.length > 0 &&
                            <Button
                                color="link"
                                size="sm"
                                className="pb-1"
                                onClick={() => deselectAll()}>
                                Deselect all ({selectedEntities.length})
                            </Button>
                        }
                    </h2>
                </Col>
            </Container>

            <Container fluid={true} className="mt-3 pe-4">
                <Col xl={12}>
                    <Row>
                        {separateFilters.length > 0 &&
                            <Col
                                sm={12}
                                md={(entityType === EntityType.CATEGORY || entityType === EntityType.UNITGROUP) ? 4 : 12}
                                lg={(entityType === EntityType.CATEGORY || entityType === EntityType.UNITGROUP) ? 4 : 12}
                                xl={(entityType === EntityType.CATEGORY || entityType === EntityType.UNITGROUP) ? 3 : 11}
                                className="mb-1">
                                <FilterRow type={type}
                                    filters={separateFilters}
                                    disabled={entities.length === 0}
                                    filterChanged={filterChanged}
                                    clearFilters={clearFilters}
                                />
                            </Col>
                        }
                        <Col
                            sm={12}
                            md={(entityType === EntityType.CATEGORY || entityType === EntityType.UNITGROUP) ? 8 : 12}
                            lg={(entityType === EntityType.CATEGORY || entityType === EntityType.UNITGROUP) ? 8 : 12}
                            xl={(entityType === EntityType.CATEGORY || entityType === EntityType.UNITGROUP) ? 9 : 12}>
                            <ButtonRow type={type}
                                buttons={enabledActions}
                                selectedEntities={selectedEntities}
                                clearFiltersClicked={() => clearFiltersClicked()}
                                clearFilterDisabled={clearFilterDisabled}
                            />
                        </Col>
                    </Row>
                </Col>
            </Container>

            <Container fluid={true} className="mb-4">
                <Col lg={12} xl={10}>
                    { loading && <CustomSpinner />}
                    { (!loading && searchError) && <Error msg={searchError} />}
                    { (!loading && infoMsg) && <Error msg={infoMsg} />}
                </Col>
                { (!loading && !searchError && !infoMsg) &&
                    <Col lg={12} xl={entityProperties.tableWidth}>
                        <CustomTableWithFilters
                            className={tableClasses}
                            tableColumns={columnsWithActions}
                            tableData={entities}
                            onSelectedChanged={onSelectedChanged}
                            hiddenColumns={hiddenColumns}
                            pageSizes={entityProperties.pageSizes}
                            ref={tableInstance}
                            localStorageKey={entityProperties.single + "Table"}
                            //setRowCount={setRows}
                            onRowCountChanged={rowCountChanged}
                            globalFilterValue={globalFilterValue}
                            //selectedIds={selectedEntities.length > 0 ? selectedEntities.map(i => i.id) : []}
                            rowSelectionDisabled={entityType !== EntityType.ITEM && !entityProperties.revisionEntity && !isStorageOwner(currentUser, selectedStorageId)}
                            showColumnFilters={separateFilters.length === 0}
                        />
                    </Col>
                }
            </Container>

            <EntityLayoutModal
                entityType={entityType}
                showModal={showModal}
                toggleModal={toggleModal}
                modalStatus={modalStatus}
                selectedEntity={selectedEntity}
                filters={tableInstance?.current ? tableInstance.current.getState().columnFilters : []}
                entityIsCreated={entityIsCreated}
                entityIsUpdated={entityIsUpdated}
                msalInstance={msalInstance}
            />
            {enabledActions && enabledActions.some(a => a.action === Action.DELETE) &&
                <DeleteModal
                    isOpen={showDeleteModal}
                    toggle={toggleDeleteModal}
                    entityProperties={entityProperties}
                    deleteIds={deleteEntities.map(i => i.id)}
                    onDeleted={entitiesDeleted}
                    onDeleteModalIsClosing={deleteModalIsCancelClosed}
                    subTitle={selectedStorage ? selectedStorage.code : ""}
                    storageId={selectedStorageId}
                    user={currentUser.authUser}
                    msalInstance={msalInstance}>
                    <SimpleCustomTable
                        className={entityProperties.revisionEntity || entityType === EntityType.ITEM || entityType === EntityType.CLIENTINTERFACE ? "table-with-states" : ""} 
                        tableColumns={combinedColumns ?? columns}
                        tableData={deleteEntities}
                        hiddenColumns={["references"]} />
                </DeleteModal>
            }
            {enabledActions && enabledActions.some(a => a.action === Action.PUBLISH) &&
                <PublishModal
                    isOpen={showPublishModal}
                    toggle={togglePublishModal}
                    entityProperties={entityProperties}
                    publishIds={selectedEntities.map(i => i.id)}
                    selectedStorageId={selectedStorageId}
                    onItemIsPublished={entitiesPublished}
                    publishStatus={publishStatus}
                    subTitle={selectedStorage ? selectedStorage.code : ""}
                    user={currentUser.authUser}
                    msalInstance={msalInstance} >
                    <SimpleCustomTable
                        className={entityProperties.revisionEntity ? "table-with-states" : ""}
                        tableColumns={combinedColumns ?? columns}
                        tableData={selectedEntities}
                        hiddenColumns={["publishedToStageBy", "publishedToProdBy"]}/>
                </PublishModal>
            }
            {enabledActions && enabledActions.some(a => a.action === Action.SETTINGS) &&
                <SettingsModal
                    isOpen={showSettingsModal}
                    toggle={toggleSettingsModal}
                    titlePart={entityProperties.plural}
                    properties={columns}
                    onSettingsUpdated={onSettingsUpdated}
                    hiddencolumns={hiddenColumns} />
                }
            {enabledActions && enabledActions.some(a => a.action === Action.LOG) &&
                <LogModal
                    isOpen={showLogModal}
                    toggle={toggleLogModal}
                    entityProperties={entityProperties}
                    baseId={selectedEntity ? selectedEntity.id : 0}
                    subTitle={selectedStorage ? selectedStorage.code : ""}
                    columns={logColumns ?? columns}
                    parentTitle={entityType === EntityType.ELEMENT ? "Item" : ""}
                    parentColumns={entityType === EntityType.ELEMENT ? ItemBaseColumns() : null}
                    user={currentUser.authUser}
                    msalInstance={msalInstance} >
                </LogModal>
            }
        </React.Fragment>
    );
}