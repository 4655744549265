import { Button } from 'reactstrap';
import { Role, Action } from './enums';

export const REFRESH_FREQUENCY_IN_MINUTES = 5;
export const VALUE_NOT_CALCULABLE = "N/A";
export const STORAGE_LOCALSTORAGE_KEY = "lastViewedStorage";
export const INIT_ELEMENT = "_INIT";
export const INIT_ELEMENT_1 = "_INIT_1";
export const INIT_ELEMENT_2 = "_INIT_2";
export const INIT_ELEMENT_3 = "_INIT_3";

export const firstLetterToUpperCase = (string) => {
    if (!string){
        return string;
    }
    return string[0].toUpperCase() + string.slice(1);
}

export const parseToNumber = (value, defaultValue) => {
    if (!value){
        return defaultValue ?? value;
    }
    return isNaN(value) ? defaultValue ?? value : parseInt(value);
}

export const isInteger = (value) => {
    //console.log('is "' + value + '" integer? ', typeof value);
    if (typeof value === 'number') {
        return Number.isInteger(value);
    }

    return false;
}

export const isFloat = (value) => {
    if (typeof value === 'number') {
        return true;
    }

    return false;
}

export const isIntegerArray = (arr) => {
    if (arr && Array.isArray(arr)) {
        if (arr.length > 0) {
            let isArray = true;
            arr.forEach(v => {
                if (!isInteger(v)) {
                    isArray = false;
                    return;
                }
            });
            return isArray;
        }
        return true; // Also empty arrays are accepted!
    }

    return false;
}

export const isFloatArray = (arr) => {
    console.log('arr', arr);
    if (arr && Array.isArray(arr)) {
        if (arr.length > 0) {
            let isArray = true;
            arr.forEach(v => {
                if (!isFloat(v)) {
                    isArray = false;
                    return;
                }
            });
            return isArray;
        }
        return true; // Also empty arrays are accepted!
    }

    return false;
}

export const isStrArray = (arr) => {
    if (arr && Array.isArray(arr)) {
        if (arr.length > 0) {
            let isValid = true;
            arr.forEach(v => {
                if (Array.isArray(v)) {
                    isValid = false;
                    return;
                }
            });
            return isValid;
        }
        return true; // Also empty arrays are accepted!
    }

    return false;
}

export const getPropertyValueByKey = (obj, key) => {
    if (Array.isArray(obj[key])) {
        return key + " = [" + obj[key].map(v => v ?? "").join(', ') + "]";
    }
    return key + " = " + obj[key];
}

export const objectToString = (obj) => {
    if (typeof obj === "object") {
        return "{" + Object.keys(obj).sort().map((key) => {
            return "\n " + getPropertyValueByKey(obj, key);
        }) + "\n}";
    }

    return obj;
}

export const getValueString = (obj) => {
    if (typeof obj === "object") {
        return Object.keys(obj).map(function (key) {
            return getPropertyValueByKey(obj, key);
        }).join(";");
    }
    return obj;
}

export const getValueCellDiv = (value, customClass, maxLength, asString = true) => {
    //console.log('value, customClass, maxLength, asString', value, customClass, maxLength, asString);
    if (value === VALUE_NOT_CALCULABLE) {
        return (<div className="bg-secondary text-custom-light">{value}</div>);
    }
    //console.log('maxLength, type & value', maxLength, typeof value, value);
    if (typeof value === "object") {
        if (asString) {
            return (<div className="line-clamp">
                {objectToString(value)}
            </div>);
        }

        let totalLength = 1;
        return (
            <div className="line-clamp d-flex flex-column">
                <span>&#123;</span>
                {
                    Object.keys(value).sort().map((key, index) => {
                        if (maxLength === 0 || totalLength < maxLength) {
                            const cellValue = getPropertyValueByKey(value, key);
                            totalLength += cellValue.length;
                            if (maxLength > 0 && totalLength >= maxLength) {
                                return (<>
                                    <span className="ms-2" key={"cell_elValue_" + index}>{cellValue}</span>
                                    <span className="ms-2" key={"cell_elValue_end_" + index}>{'\u2026'}</span>
                                </>)
                            }
                            return (<span className="ms-2" key={"cell_elValue_" + index}>{cellValue}</span>)
                        }

                        return "";
                    })
                }
                <span>&#125;</span>
            </div>
        );
    }

    if (value?.length > 0) {
        const allClasses = customClass?.length > 0 ? customClass + " line-clamp" : "line-clamp";
        return (<div className={allClasses}>{getTruncatedValue(value, maxLength)}</div>);
    }
    return getEmptyCellDiv();
}

export const getEmptyCellDiv = () => {
    return (<div className="empty"></div>);
}

export const getCellDiv = ({value, columnId, customClass = "", maxLength = 0}) => { //console.log('cellDiv', columnId, maxLength, value);
    const supportUrlColumns = ["description", "itemDescription", "internalNotes", "internalId", "comments"];
    const noEmptySupport = ["references", "batchValue"];
    let classes = customClass ?? "";
    let allClasses = maxLength > 0 ? classes + " line-clamp" : classes;
    if (!columnId) {
        return getEmptyCellDiv();
    }
    if (!value || value.length === 0) {
        // We need return the value cause it can be 0.
        return noEmptySupport.includes(columnId) ? (<div>{value}</div>) : getEmptyCellDiv();
    }

    if (columnId === "value"){
        return getValueCellDiv(value, allClasses, maxLength);
    }

    if (supportUrlColumns.includes(columnId)) {
        return getUrlDiv(value, columnId, allClasses, maxLength);
    }

    return (<div className={allClasses}>{getTruncatedValue(value, maxLength)}</div>);
}

export const getUrlDiv = (value, columnId, customClass = "", maxLength = 0) => {
    if (!value || value.length === 0){
        return (<div className={customClass}>{getTruncatedValue(value, maxLength)}</div>);
    }

    //console.log('value', value);
    let valueWithoutEnter = value;
    const enterRegex = /\r|\n/g;
    const match = enterRegex.exec(value);
    if (match){
        valueWithoutEnter = value.replaceAll(enterRegex, ' ');
    }
    const urlRegex = /(https?:\/\/[^ ]*)/g;
    const urlMatch = [...valueWithoutEnter.matchAll(urlRegex)]; //console.log('urlMatch', urlMatch);
    if (urlMatch && urlMatch.length > 0){
        //if (urlMatch.length === 1)
        let inputIndex = 0;
        return (
            <div className={customClass}>
                {urlMatch.map((match, index)  => {
                    const url = match[1];//console.log('match', match);
                    const startIndex = inputIndex;//console.log('startIndex', startIndex);
                    const strLength = match.index - inputIndex;//console.log('strLength', strLength);
                    inputIndex = match.index + url.length;
                    if (index === urlMatch.length - 1){ // Are we in last match
                        return (
                            <span key={columnId + "_urlDiv_" + index}>
                                {valueWithoutEnter.substr(startIndex, strLength)}
                                <Button color="link" className="p-0 color-inherit" href={url} target="_blank">
                                    (LINK) 
                                </Button>
                                {valueWithoutEnter.substr(match.index + url.length)}
                            </span>);
                    }
                    else{
                        return (
                            <span key={columnId + "_urlDiv_" + index}>
                                {valueWithoutEnter.substr(startIndex, strLength)}
                                <Button color="link" className="p-0 color-inherit" href={url} target="_blank">
                                    (LINK) 
                                </Button>
                            </span>
                        )
                    }
                })}
            </div>
        );
    }

    return (<div className={customClass}>{value}</div>);
}

export const includesDuplicates = (array, key) => {
    return new Set(array.map(i => i[key])).size !== array.length;
}

export const getTruncatedValue = (value, maxLength) => {
    if (!maxLength || maxLength === 0 || !value){
        return value;
    }

    if (value.length < maxLength){
        return value;
    }
    if (typeof value !== "string") {
        return value;
    }

    return value.substring(0, maxLength) + '\u2026';
}

export const getWithStyledComments = (value) => {
    if (value?.length > 0) {
        return value.replace(/^--(.*)$/mg, "<span class='siteComment'>--$1</span>");
    }
    return value;
}

export const getSubMenuItems = (allMenuItems, userHighestRole) => {
    // Let's return empty array if user does not have any roles or there is no menu items
    if (!userHighestRole || userHighestRole === 0 || !allMenuItems || allMenuItems.length === 0) {
        return [];
    }

    // If user is admin all the menu items can be returned
    if (userHighestRole === Role.ADMIN){
        return allMenuItems;
    }

    // Let's return menu items that are allowed for the user
    return allMenuItems.filter(i => userHighestRole >= i.requiredRole);
}

export const getOption = (value, mainLabel, secondaryLabel, symbol) => {
    if (symbol) {
        return { value: value, label: mainLabel, subLabel: secondaryLabel, symbolName: symbol };
    }
    return { value: value, label: mainLabel, subLabel: secondaryLabel };
}

export const getFactoryOptions = (factories, requiredRole = Role.NONE) => {
    if (!factories || factories.length === 0) {
        return [];
    }
    return factories.filter(f => f.userRole >= requiredRole).map(factory => { return getOption(factory.id, factory.code, factory.name, factory.factorySymbol) });
}

export const getFlagOptions = (flags) => {
    if (!flags || flags.length === 0) {
        return [];
    }
    return flags.map(f => { return { label: f.name, value: f.id, symbolName: f.symbol} });
}

export const getSelectedOption = (options, id) => {
    if (!options || options.length === 0 || !id || id === 0) {
        return []
    }
    const option = options.filter(o => o.value === id);
    if (option && option.length > 0) {
        return option[0];
    }

    return [];
}

export const getButtonText = (status) => {
    if (!status || typeof status !== "string") {
        return "";
    }
    if (status === Action.EDIT || status === Action.SETTINGS) {
        return "Save";
    } 
    if (status === Action.COPY) {
        return "Create";
    }
    if (status === Action.CHANGE) {
        return "OK";
    }

    return status;
}

export const isAdmin = (currentUser) => {
    if (currentUser) {
        if (currentUser.highestRole === Role.ADMIN) {
            return true;
        }
    }

    return false;
}

export const isStorageOwner = (currentUser, storageId) => {
    if (currentUser) {
        if (isAdmin(currentUser)) {
            return true;
        }
        if (storageId && currentUser.storageOwnerIds &&
            currentUser.storageOwnerIds.includes(storageId)) {
            return true;
        }
    }

    return false;
}

export const isStorageContributor = (currentUser, storageId) => {
    if (currentUser) {
        if (isStorageOwner(currentUser, storageId)) {
            return true;
        }

        if (storageId && currentUser.roles && currentUser.roles.length > 0) {
            return currentUser.roles.some(r => r.storageId === storageId &&
                r.role >= Role.CONTRIBUTOR);
        }
    }

    return false;
}

export const hasContributorRole = (currentUser, storageId, selectedFactories) => {
    if (currentUser) {
        if (isStorageOwner(currentUser, storageId)) {
            return true;
        }

        if (storageId) { 
            if (Array.isArray(selectedFactories)) {
                // Array of factory ids passed
                return currentUser.roles.some(r => r.storageId === storageId &&
                    r.factoryId !== null &&
                    selectedFactories.includes(r.factoryId) &&
                    r.role >= Role.CONTRIBUTOR);
            }

            // Single factory id passed
            return currentUser.roles.some(r => r.storageId === storageId &&
                r.factoryId === selectedFactories &&
                r.role >= Role.CONTRIBUTOR);
        }
    }

    return false;
}

export const hasRoleForStorageAndFactory = (currentUser, role, storageId, factoryId) => {
    //console.log('hasRoleForStorageAndFactory', currentUser, role, storageId, factoryId);
    if (currentUser) {
        if (role) {
            if (role === Role.ADMIN) {
                return isAdmin(currentUser);
            }

            if (isStorageOwner(currentUser, storageId)) {
                return true;
            }

            if (storageId && factoryId) {
                return currentUser.roles.some(r => r.storageId === storageId &&
                    r.factoryId === factoryId &&
                    r.role >= role);
            }
        }
    }

    return false;
}

export const hasPublishingRole = (currentUser, status, storageId, selectedFactories, allRequired = true) => {
    if (currentUser) {
        if (status) {
            if (isStorageOwner(currentUser, storageId)) {
                return true;
            }
            if (Array.isArray(selectedFactories)) {
                if (selectedFactories.length === 0) {
                    return false;
                }
                // Array of factory ids passed
                let userHasPublishingRights = true;
                // Do the user need publishing permission to all selected factories?
                if (allRequired) {
                    selectedFactories.forEach(id => {
                        if (!currentUser.roles.some(r => r.storageId === storageId &&
                            r.factoryId === id &&
                            r.role >= Role.CONTRIBUTOR &&
                            r.publishingRole >= status)) {
                                userHasPublishingRights = false;
                        }
                    });
                }
                else {
                    // User only needs to have publishing permission for one of the factories
                    if (!(currentUser.roles.filter(r => r.storageId === storageId &&
                        selectedFactories.includes(r.factoryId) &&
                        r.role >= Role.CONTRIBUTOR &&
                        r.publishingRole >= status))) {
                        userHasPublishingRights = false;
                    }
                }

                return userHasPublishingRights;
            }

            // Single factory id passed
            return currentUser.roles.some(r => r.storageId === storageId &&
                r.factoryId === selectedFactories &&
                r.role >= Role.CONTRIBUTOR &&
                r.publishingRole >= status);
        }
    }

    return false;
}