import { UserInputType, UserInputTypeText, RevisionStatus, RevisionStatusText, UnitType, UnitTypeText, Role, ActionStatus } from '../../helpers/enums';
import { callAPI } from '../../helpers/api';
import workspaceActions from '../../actions/workspaceActions';
import {
    REFRESH_FREQUENCY_IN_MINUTES,
    includesDuplicates,
    getOption,
    isInteger,
    isFloat,
    isIntegerArray,
    isFloatArray,
    isStrArray
} from '../../helpers/commonHelpers';

export const SUPPORTS_ALL_FACTORIES = "GLOBAL";
export const CUSTOM_ELEMENT_TYPE = {id: 0, code: "Custom"};

export const subMenuItems = [
    {
        name: 'Items & Elements',
        to: '/workspace/elements',
        icon: './icons/workspace_item_element.svg',
        requiredRole: Role.READER
    },
    {
        name: 'Parameters',
        to: '/workspace/parameters',
        icon: './icons/workspace_parameter.svg',
        requiredRole: Role.READER
    },
    {
        name: 'Value types',
        to: '/workspace/valueTypes',
        icon: './icons/workspace_value_type.svg',
        requiredRole: Role.READER
    },
    {
        name: 'Element types',
        to: '/workspace/elementTypes',
        icon: './icons/workspace_element_type.svg',
        requiredRole: Role.READER
    },
    {
        name: 'Categories',
        to: '/workspace/categories',
        icon: './icons/workspace_category.svg',
        requiredRole: Role.READER
    },
    {
        name: 'Unit groups',
        to: '/workspace/unitGroups',
        icon: './icons/workspace_unit_group.svg',
        requiredRole: Role.READER
    },
    {
        name: 'Units',
        to: '/workspace/units',
        icon: './icons/workspace_unit.svg',
        requiredRole: Role.READER
    },
    {
        name: 'Client inputs',
        to: '/workspace/client',
        icon: './icons/workspace_client_input.svg',
        requiredRole: Role.READER
    },
];

export const inputTypes = () => {
    var types = [
        { value: UserInputType.STRING, label: UserInputTypeText.STRING },
        { value: UserInputType.INTEGER, label: UserInputTypeText.INTEGER },
        { value: UserInputType.FLOAT, label: UserInputTypeText.FLOAT }
    ];

    return types;
}

export const extendedInputTypes = () => {
    return [
        ...inputTypes(),
        { value: UserInputType.STRING_ARRAY, label: UserInputTypeText.STRING_ARRAY},
        { value: UserInputType.INTEGER_ARRAY, label: UserInputTypeText.INTEGER_ARRAY},
        { value: UserInputType.FLOAT_ARRAY, label: UserInputTypeText.FLOAT_ARRAY }
    ]
}

export const extendedInputTypesWithDictionaries = () => {
    return [
        ...extendedInputTypes(),
        { value: UserInputType.STRING_DICT, label: UserInputTypeText.STRING_DICT },
        { value: UserInputType.INTEGER_DICT, label: UserInputTypeText.INTEGER_DICT },
        { value: UserInputType.FLOAT_DICT, label: UserInputTypeText.FLOAT_DICT },
        { value: UserInputType.STRING_ARRAY_DICT, label: UserInputTypeText.STRING_ARRAY_DICT },
        { value: UserInputType.INTEGER_ARRAY_DICT, label: UserInputTypeText.INTEGER_ARRAY_DICT },
        { value: UserInputType.FLOAT_ARRAY_DICT, label: UserInputTypeText.FLOAT_ARRAY_DICT }
    ]
}

export const getAvailableParameters = (valueType, parameters, globalFactory) => {
    if (valueType && valueType.id){
        if (valueType.supportsAllFactories){
            if (globalFactory){
                return getAvailableParametersByFactories([globalFactory.id], parameters, globalFactory.id);
            }
            return [];
        }
        else{
            return getAvailableParametersByFactories(valueType.supportedFactories.map(f => f.id), parameters, globalFactory ? globalFactory.id : null);
        }
    }
    else{
        return [];
    }
}

export const getAvailableParametersByFactories = (factoryIds, parameters, globalFactoryId) => {
    if (factoryIds && factoryIds.length > 0){
        var availableParams = parameters.filter(p => factoryIds.includes(p.factoryId)); //console.log('availableParameters for factories, first global', globalFactoryId, factoryIds, availableParams);
        // Remove all duplicate parameters (parameters with same code).
        // If duplicates exist remove the ones related to GLOBAL factory.
        if (availableParams.length > 0 && factoryIds.length > 1 && globalFactoryId && globalFactoryId > 0){
            if (includesDuplicates(availableParams, "code")){ 
                // Duplicates found
                const codeCounts = availableParams.reduce((allCounts, param) =>
                ({
                    ...allCounts,
                    [param.code]: (allCounts[param.code] || 0) + 1
                }), {}); //console.log('codeCounts', codeCounts);
                const duplicates = Object.keys(codeCounts).filter(c => codeCounts[c] > 1);//console.log('duplicates', duplicates);
                const paramsNoDuplicates = availableParams.filter(a => !duplicates.includes(a.code) || (duplicates.includes(a.code) && a.factoryId !== globalFactoryId)); //console.log('paramsNoDuplicates', paramsNoDuplicates);
                return paramsNoDuplicates;
            }
        }
        return availableParams;
    }
    else{
        return [];
    }
}

export const validateFormulaFromServer = async (
    formula, 
    userInputs, 
    storage,
    selectedFactory, 
    column,
    checkType,
    baseType,
    isInEditMode,
    msalInstance,
    user
) => {
    //console.log('checkType', checkType);
    let returnObject = { errorMsg: "", value: "", validationIsNull: false, notFound: false, noValue: false };
    if (!formula || formula.length === 0){
        return returnObject;
    }

    let inputs = getParams(userInputs); //console.log('inputs', userInputs, inputs);
    
    let data = {
        storageId: storage?.id ?? 0,
        storageCode: storage?.code,
        factoryId: selectedFactory?.value ?? 0,
        formula: formula, 
        userInputs: inputs, 
        shortError: true,
        isInEditMode: isInEditMode
    }; //console.log('data', data);
    const result = await callAPI("scripting", msalInstance, user, data); //console.log('result', result);
    if (result.errorMsg){
        return { ...returnObject, errorMsg: result.errorMsg, notFound: result.notFoundError };
    }

    const valuation = result.json;
    if (valuation){
        if (valuation.ok){
            const returnedValue = valuation.value; //console.log('baseType & returnedValue', baseType, returnedValue);
            if (valuation.noValue) {
                return { ...returnObject, noValue: true };
            }

            if (returnedValue === false) {
                return { ...returnObject, value: "false" };;
            }
            if ((!returnedValue && returnedValue !== 0)){
                // The scripting api returned null as validation.
                return { ...returnObject, validationIsNull: true };
            }
            returnObject.value = returnedValue;

            if (returnedValue && Array.isArray(returnedValue)) {
                returnObject.value = "[" + returnedValue.map(v => v ?? "").join(', ') + "]";
            } 
            
            if (checkType) {
                return checkAndReturnEvaluation(returnedValue, returnObject, baseType, column);
            }
        }
        else if (valuation.errorMsg && valuation.errorMsg.length > 0) {
            return { ...returnObject, errorMsg: valuation.errorMsg, notFound: valuation.notFoundError };
        }
    }

    return returnObject;
}

export const shouldBeRefreshed = (lastRefreshed) => {
    if (!lastRefreshed){
        return true;
    }

    const now = new Date();//console.log('now', now);
    const addedTime = lastRefreshed + REFRESH_FREQUENCY_IN_MINUTES*60000;//console.log('added minutes', new Date(addedTime));
    if (now.getTime() > addedTime){ //console.log(now.getTime() + ' > ' + addedTime + '? yes');
        return true;
    }

    //console.log(now.getTime() + ' > ' + addedTime + '? no');
    return false;
}

export const getBaseType = (typeId, currentValue) => {
    //console.log('typeId, currentValue: ', typeId, currentValue);
    switch (typeId) {
        case UserInputType.STRING:
            return UserInputTypeText.STRING;
        case UserInputType.INTEGER:
            return UserInputTypeText.INTEGER;
        case UserInputType.FLOAT:
            return UserInputTypeText.FLOAT;
        case UserInputType.STRING_ARRAY:
            return UserInputTypeText.STRING_ARRAY;
        case UserInputType.INTEGER_ARRAY:
            return UserInputTypeText.INTEGER_ARRAY;
        case UserInputType.FLOAT_ARRAY:
            return UserInputTypeText.FLOAT_ARRAY;
        case UserInputType.STRING_DICT:
            return UserInputTypeText.STRING_DICT;
        case UserInputType.INTEGER_DICT:
            return UserInputTypeText.INTEGER_DICT;
        case UserInputType.FLOAT_DICT:
            return UserInputTypeText.FLOAT_DICT;
        case UserInputType.STRING_ARRAY_DICT:
            return UserInputTypeText.STRING_ARRAY_DICT;
        case UserInputType.INTEGER_ARRAY_DICT:
            return UserInputTypeText.INTEGER_ARRAY_DICT;
        case UserInputType.FLOAT_ARRAY_DICT:
            return UserInputTypeText.FLOAT_ARRAY_DICT;
        default:
            return currentValue ?? "";
    };
}

export const getTypeById = ({ value: typeId, toLowerCase = false }) => {
    var str = "";
    switch (typeId) {
        case UserInputType.STRING:
            str = UserInputTypeText.STRING;
            break;
        case UserInputType.INTEGER:
            str = UserInputTypeText.INTEGER;
            break;
        case UserInputType.FLOAT:
            str = UserInputTypeText.FLOAT;
            break;
        case UserInputType.STRING_ARRAY:
            str = UserInputTypeText.STRING_ARRAY;
            break;
        case UserInputType.INTEGER_ARRAY:
            str = UserInputTypeText.INTEGER_ARRAY;
            break;
        case UserInputType.FLOAT_ARRAY:
            str = UserInputTypeText.FLOAT_ARRAY;
            break;
        case UserInputType.STRING_DICT:
            str = UserInputTypeText.STRING_DICT;
            break;
        case UserInputType.INTEGER_DICT:
            str = UserInputTypeText.INTEGER_DICT;
            break;
        case UserInputType.FLOAT_DICT:
            str = UserInputTypeText.FLOAT_DICT;
            break;
        case UserInputType.STRING_ARRAY_DICT:
            str = UserInputTypeText.STRING_ARRAY_DICT;
            break;
        case UserInputType.INTEGER_ARRAY_DICT:
            str = UserInputTypeText.INTEGER_ARRAY_DICT;
            break;
        case UserInputType.FLOAT_ARRAY_DICT:
            str = UserInputTypeText.FLOAT_ARRAY_DICT;
            break;
        default:
            return "";
    };

    return toLowerCase === true && str.length > 0 ? str.toLowerCase() : str;
}

export const getRevisionStatusText = (revisionStatus) => {
    if (revisionStatus === RevisionStatus.LATEST_REVISION){
        return RevisionStatusText.LATEST_REVISION;
    }

    if (revisionStatus === RevisionStatus.EARLIER_REVISION){
        return RevisionStatusText.EARLIER_REVISION;
    }

    return RevisionStatusText.NO_REVISION;
}

export const getActionStatusText = (actionStatus) => {
    switch (actionStatus) {
        case ActionStatus.DELETE:
            return "Delete";
        case ActionStatus.CREATE:
            return "Create";
        case ActionStatus.CREATE_NEW_REVISION:
            return "Revise";
        case ActionStatus.EDIT:
            return "Edit";
        case ActionStatus.STAGE:
            return "Publish to stage";
        case ActionStatus.PROD:
            return "Publish to production";
        default:
            return actionStatus;
    }
}

export const getUnitType = (typeId, currentValue) => {
    switch (typeId){
        case UnitType.NOT_PREDEFINED:
            return UnitTypeText.NOT_PREDEFINED;
        case UnitType.GROUP_PREDEFINED:
            return UnitTypeText.GROUP_PREDEFINED;
        case UnitType.UNIT_PREDEFINED:
            return UnitTypeText.UNIT_PREDEFINED;
        default:
            return currentValue ?? "";
    };
}

export const getUnitTypeById = ({ value: typeId }) => {
    switch (typeId) {
        case UnitType.NONE:
            return UnitTypeText.NONE;
        case UnitType.OPTIONAL:
            return UnitTypeText.OPTIONAL;
        case UnitType.NOT_PREDEFINED:
            return UnitTypeText.NOT_PREDEFINED;
        case UnitType.GROUP_PREDEFINED:
            return UnitTypeText.GROUP_PREDEFINED;
        case UnitType.UNIT_PREDEFINED:
            return UnitTypeText.UNIT_PREDEFINED;
        default:
            return typeId;
    };
}

export const setStorageFactories = async (storageId, dispatch, msalInstance, user) => {
    return new Promise((resolve) => {
        getStorageEntities('factory/storageList?storageIds=' + storageId, msalInstance, user)
            .then((response) => {
                //console.log('Set factories', response); 
                dispatch(workspaceActions.setStorageFactories(response))
                resolve(response);
            });
    });
}

export const setStorageUnits = async (storageId, dispatch, msalInstance, user) => {
    return new Promise((resolve) => {
        getStorageEntities('unit/storage/' + storageId, msalInstance, user)
        .then((response) => {
            //console.log('Set units', response); 
            dispatch(workspaceActions.setStorageUnits(response))
            resolve(true);
        });
    });
}

export const setStorageValueTypes = async (storageId, dispatch, msalInstance, user) => {
    return new Promise((resolve) => {
        getStorageEntities('valueType/storage/' + storageId + '?all=true', msalInstance, user)
        .then((response) => {
            //console.log('Set value types', response); 
            dispatch(workspaceActions.setStorageValueTypes(response))
            resolve(true);
        });
    });
}

export const setStorageCategories = async (storageId, dispatch, msalInstance, user) => {
    return new Promise((resolve) => {
        getStorageEntities('category/storage/' + storageId, msalInstance, user)
        .then((response) => {
            //console.log('Set categories', response); 
            dispatch(workspaceActions.setStorageCategories(response))
            resolve(true);
        });
    });
}

export const setStorageElementTypes = async (storageId, dispatch, msalInstance, user) => {
    return new Promise((resolve) => {
        getStorageEntities('elementType/storage/' + storageId, msalInstance, user)
        .then((response) => {
            //console.log('Set element types', response); 
            dispatch(workspaceActions.setStorageElementTypes(response))
            resolve(response);
        });
    });
}

export const setStorageUnitGroups = async (storageId, dispatch, msalInstance, user) => {
    return new Promise((resolve) => {
        getStorageEntities('unitGroup/storage/' + storageId, msalInstance, user)
        .then((response) => {
            //console.log('Set unit groups', response); 
            dispatch(workspaceActions.setStorageUnitGroups(response))
            resolve(response);
        });
    });
}

export const getUnitOptions = (units, unitGroupId) => {
    //console.log('units', units);
    if (!units || units.length === 0){
        return [];
    }

    if (unitGroupId && unitGroupId > 0){
        return units.filter(u => u.groupId === unitGroupId).map(unit => { return getOption(unit.id, unit.group + ": " + unit.code, unit.description)});
    }
    
    return units.map(unit => { return getOption(unit.id, unit.group + ": " + unit.code, unit.description)});
}

export const getFactoryString = ({ revisionData }) => {
    if (revisionData.isSubstitutable) {
        return revisionData.factory + " (subst.)";
    }
    return revisionData.factory;
}

export const revisionPropertiesAreSame = (current, other, propertyName) => {
    if ((!current && !other)) {
        return true;
    }

    if ((!current && other) || (current && !other)) {
        return false;
    }

    if ((!current[propertyName] && !other[propertyName])) {
        return true;
    }

    if (current[propertyName]) {
        return current[propertyName].localeCompare(other[propertyName]) === 0;
    }
    return false;
}

export const userInputsAreSame = (current, other) => {
    //console.log('current, other', current, other);
    if (!current && !other) {
        return true;
    }

    if ((!current && other) || (current && !other)) {
        return false;
    }

    if (!current.input || current.input.length === 0){
        // Current input property is not defined
        if (other.input && other.input.length > 0){
            return false;
        }

        // Let's check that the userInputs for current and the other match
        //console.log('current and other', current, other);
        const currentInputs = current.valueType && current.valueType.userInputs && current.valueType.userInputs.length > 0 ? current.valueType.userInputs : [];
        const otherInputs = other.valueType && other.valueType.userInputs && other.valueType.userInputs.length > 0 ? other.valueType.userInputs : [];
        
        if (currentInputs.length !== otherInputs.length){
            return false;
        }
        if (currentInputs.length === 0){
            return true;
        }
        if (currentInputs.some(input => otherInputs.filter(obj => obj.code === input.code && obj.value === input.value).length === 0)){
            return false;
        }

        return true;
    }
    else {
        if (!other.input || other.input.length === 0){
            return false;
        }
        return current.input.localeCompare(other.input) === 0;
    }
}

// private functions
const getStorageEntities = async (url, msalInstance, user) => {
    //console.log('In setStorageEntity method!!!');
    if (url.length > 0){
        let result = await callAPI(url, msalInstance, user);//console.log('Refreshed entities for ' + url, storageId, result);
        var list = result.json;
        if (result.errorMsg && !list){
            return [];
        }
        else{
            return list;
        }
    }
    else{
        return [];
    }
}

const getParams = (inputs) =>{
    let params = {
        integers: {},
        floats: {},
        strings: {}
    };
    if (inputs && inputs.length > 0){
        inputs.forEach((input) => {
            //console.log('input', input);
            if (input.typeId === UserInputType.INTEGER){
                params.integers[input.code] = input.value ?? 0;
            }
            else if(input.typeId === UserInputType.FLOAT){
                params.floats[input.code] = input.value ?? 0;
            }
            else{
                //params.strings[input.code] = input.value ? encodeURIComponent(input.value) : "";
                params.strings[input.code] = input.value ?? "";
            }
        });
    }

    return params;
}

const getNotRightTypeMessage = (column, id, label) => { //console.log('getNotRightTypeMessage', typeId);
    return column + ' is not of right type! The type should be ' + (label && label.length > 0 ? label : getTypeById({ value: id })) + '.';
}

const checkIsNumericAndValid = (type, value) => {
    if ((type === UserInputType.INTEGER && !isInteger(value)) ||
        (type === UserInputType.FLOAT && !isFloat(value))) {
        return false;
    }

    // For other types let's return true
    return true;
}

const isArrayByType = (type) => {
    if (type === UserInputType.INTEGER_ARRAY || type === UserInputType.STRING_ARRAY || type === UserInputType.FLOAT_ARRAY) {
        return true;
    }

    return false;
}

const isValidArray = (type, arr) => {
    if (!Array.isArray(arr)) {
        //console.log('isValidArray value', Array.isArray(value), value);
        // Not an array
        return false;
    }

    // Check numeric arrays
    if ((type === UserInputType.INTEGER_ARRAY && !isIntegerArray(arr)) ||
        (type === UserInputType.FLOAT_ARRAY && !isFloatArray(arr)) ||
        (type === UserInputType.INTEGER_DICT && !isIntegerArray(arr)) ||
        (type === UserInputType.FLOAT_DICT && !isFloatArray(arr))) {
        return false;
    }

    return true;
}

const isValidArrayByMethod = (arr, method) => {
    console.log('isValidArray value', Array.isArray(arr), arr);
    if (!Array.isArray(arr)) {
        // Not an array
        return false;
    }
    let isValid = true;
    arr.forEach(v => {
        if (!method(v)) {
            isValid = false;
            return;
        }
    });
    return isValid;
}

const isDictionaryByType = (type) => {
    if (type >= UserInputType.STRING_DICT && type <= UserInputType.FLOAT_ARRAY_DICT) {
        return true;
    }

    return false;
}

const isValidDictionary = (type, value) => {
    if (typeof value !== "object") {
        return false;
    }

    const dictValues = Object.keys(value).map(k => { return value[k] }); //console.log('dictValues', dictValues);
    // string dictionary
    if (type === UserInputType.STRING_DICT) {
        if (!isStrArray(dictValues)) {
            return false;
        }
    }

    if (type === UserInputType.INTEGER_DICT || type === UserInputType.FLOAT_DICT) {
        if (!isValidArray(type, dictValues)) {
            return false;
        }
    }

    if (type === UserInputType.STRING_ARRAY_DICT) {
        // Check that all the values in dictionary are valid arrays
        return isValidArrayByMethod(dictValues, isStrArray);
    }

    if (type === UserInputType.INTEGER_ARRAY_DICT) {
        // Check that all the values in dictionary are valid arrays
        return isValidArrayByMethod(dictValues, isIntegerArray);
    }

    if (type === UserInputType.FLOAT_ARRAY_DICT) {
        // Check that all the values in dictionary are valid arrays
        return isValidArrayByMethod(dictValues, isFloatArray);
    }

    return true;
}

const checkAndReturnEvaluation = (value, returnObject, baseType, column) => {
    // For string let's check value is not an array or a dictionary.
    if (baseType === UserInputType.STRING) {
        if (Array.isArray(value) || typeof value === "object") {
            return { ...returnObject, errorMsg: getNotRightTypeMessage(column, baseType) };
        }
        return returnObject;
    }

    // Numeric values
    if (!checkIsNumericAndValid(baseType, value)) {
        return { ...returnObject, errorMsg: getNotRightTypeMessage(column, baseType) };
    }

    // Check arrays
    if (isArrayByType(baseType)) {
        if (!isValidArray(baseType, value)) { 
            return { ...returnObject, errorMsg: getNotRightTypeMessage(column, baseType) };
        }

        return returnObject;
    }

    // Check dictionaries
    if (isDictionaryByType(baseType)) {
        //returnObject.value = value; //console.log('returnObject', returnObject);
        if (!isValidDictionary(baseType, value)) {
            return { ...returnObject, errorMsg: getNotRightTypeMessage(column, baseType) };
        }
    }

    return returnObject;
}
